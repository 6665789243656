import Viewport from './viewportTracking'
import { impression, viewPromotion } from '@kissui/helpers/src/gtmEvents'
import { isObjectEmpty, removeEmptyValues } from '@kissui/helpers/src/utils'

export default function (node, campaign) {
    const tracking = new ZoneTracking(node, campaign)

    return {
        destroy: () => {
            if (!tracking.getIom().hasIntersectionObserver()) {
                tracking.destroy()
            }
        }
    }
}

class ZoneTracking extends Viewport {
    constructor(node, data) {
        super(node)
        this.data = data
    }

    runTrack() {
        const cleanedData = removeEmptyValues(this.data)
        if (cleanedData && !isObjectEmpty(cleanedData)) {
            impression({ ecommerce: { promoView: { promotions: [cleanedData] } } })
            viewPromotion({ ...cleanedData })
        }
    }
}
