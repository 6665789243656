export { waitForSelector } from './waitForSelector'

export const constants = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ESC: 27,
    SPACE: 32,
    ENTER: 13,
    TAB: 9
}

export function capitalize(s = '') {
    return s[0].toUpperCase() + s.slice(1)
}

export const convertToCamelCase = (str: string): string => {
    const arr = str.match(/[a-z]+|\d+/gi)
    if (!arr) { return str }
    return arr.map((m, i) => {
        let low = m.toLowerCase()
        if (i !== 0) {
            low = low.split('').map((s, k) => (k === 0 ? s.toUpperCase() : s)).join("")
        }
        return low
    }).join("")
}

export function slug(s = '') {
    return s
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
}

export function pxToEm(target: number, stripedInnerFontSize = 1) {
    return target / 14 / stripedInnerFontSize + 'em'
}

export function percent(target: number) {
    return target + '%'
}

export function parseHTML(str: string) {
    const tmp = document.implementation.createHTMLDocument('')
    tmp.body.innerHTML = str
    return tmp.body.childNodes
}

export function stripHTML(str: string) {
    const tmp = document.implementation.createHTMLDocument('')
    tmp.body.innerHTML = str
    return (tmp.body.textContent ?? "").replace(RegExp('[\\s|\'|"]', 'g'), '')
}

export function makeId(length: number) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export function removeEmptyValues(obj: { [key: string]: any }): { [key: string]: any } {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            delete obj[key]
        }
    }

    return obj
}

export function makeHash(str: string) {
    var hash = 0,
        i,
        chr
    if (!str) {
        return hash
    }
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return 'id-' + hash
}

export function getHashLink(link: string) {
    let linkHash = link
    let linkNoHash = link
    if (link.indexOf('#') !== -1) {
        linkNoHash = link.replace('#', '')
    } else {
        linkHash = '#' + link
    }

    return { linkNoHash, linkHash }
}

export function lazyLoad(node: Element, attribute: string, value: any, url: string) {
    const CLASSNAME_OUT_OF_SCREEN = 'lazy-load'
    const CLASSNAME_IN_SCREEN = 'lazy-loaded'
    const CLASSNAME_ON_ERROR = 'lazy-loaded-error'

    const isOldIOS = () => {
        var agent = window.navigator.userAgent,
            start = agent.indexOf('OS ')
        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            return window.Number(agent.substr(start + 3, 3).replace('_', '.')) < 14
        }
        return false
    }

    const inViewPort = (attribute: string, value: any) => {
        node.setAttribute(attribute, value)

        const cb = () => node.classList.add(CLASSNAME_IN_SCREEN)

        if (url) {
            const img = new Image()
            img.src = url
            img.onload = cb
            img.onerror = () => {
                cb()
                node.classList.add(CLASSNAME_ON_ERROR)
                throw new Error(`Image ${url} cannot be loaded`)
            }

            return
        }

        cb()
    }

    if (/Trident\/|MSIE/.test(window.navigator.userAgent) || isOldIOS()) {
        inViewPort(attribute, value)
    } else {
        if ('IntersectionObserver' in window) {
            node.classList.add(CLASSNAME_OUT_OF_SCREEN)
            let lazyBackgroundObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        inViewPort(attribute, value)
                        lazyBackgroundObserver.unobserve(entry.target)
                    }
                })
            })
            lazyBackgroundObserver.observe(node)
        } else {
            inViewPort(attribute, value)
        }
    }
}

export function lazyLoadCallback(node: Element, cb: () => void) {
    const isOldIOS = () => {
        var agent = window.navigator.userAgent,
            start = agent.indexOf('OS ')
        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            return window.Number(agent.substr(start + 3, 3).replace('_', '.')) < 14
        }
        return false
    }

    if (/Trident\/|MSIE/.test(window.navigator.userAgent) || isOldIOS()) {
        cb()
    } else {
        if ('IntersectionObserver' in window) {
            let lazyBackgroundObserver = new IntersectionObserver(
                function (entries) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            cb()
                            lazyBackgroundObserver.unobserve(entry.target)
                        }
                    })
                },
                { rootMargin: '150% 0px' }
            )
            lazyBackgroundObserver.observe(node)
        }
    }
}

// Debounce
export function debounce<T>(func: (v: T) => void, time = 100) {
    let timer: number
    return function (event: T) {
        if (timer) {
            window.clearTimeout(timer)
        }
        timer = window.setTimeout(func, time, event)
    }
}

// isIE - to check for internet explorer
export function isIE() {
    let ua = window.navigator.userAgent,
        isIE = /MSIE|Trident/.test(ua)

    return isIE
}

// Load external script
export const loadExternalScript = ({
    src,
    callback = null,
    async = false,
    defer = false,
    module = false,
    id = ''
}: {
    src: string;
    callback: null | GlobalEventHandlers["onload"]
    module: boolean,
    defer: boolean,
    async: boolean,
    id: string
}) => {
    const script = document.createElement('script')
    script.type = module ? 'module' : 'text/javascript'
    script.src = src
    id ? (script.id = id) : false // add id attribute only if passed
    if (typeof callback === 'function') {
        script.onload = callback
    }
    script.async = async
    script.defer = defer
    document.body.appendChild(script)
}

// Load external css
export const loadExternalCss = ({ src }: { src: string }) => {
    const script = document.createElement('link')
    script.rel = 'stylesheet'
    script.href = src
    document.body.appendChild(script)
}
// Lazy load vendor script
export const lazyLoadVendorScript = (handler, el) => {
    const observer = new IntersectionObserver(handler)
    observer.observe(el)
}

/**
 * Replaces variable inside a given string
 * Each variable have to be enclosed between stChr and enChr
 * The values have to be contained in the vars object, they key must match
 * example :
 * txt : 'See {resultsLength} Results'
 * vars : {resultsLength:'30'}
 * stChr : '{'
 * enChr : '}'
 *
 * will return : 'See 30 Results'
 *
 * @param {string} txt
 * @param {object} vars
 * @param {string} stChr
 * @param {string} enChr
 */
export function interpolate(txt: string, vars: Record<string, null | undefined | string>, stChr: string, enChr: string) {
    let curIdx = 0

    while (txt) {
        const stIdx = txt.indexOf(stChr, curIdx)
        if (stIdx === -1) {
            break
        }
        const enIdx = txt.indexOf(enChr, stIdx + 1)
        if (enIdx === -1) {
            break
        }
        const hashId = txt.substring(stIdx + stChr.length, enIdx)
        if (vars[hashId] != null) {
            txt = txt.substr(0, stIdx) + vars[hashId] + txt.substr(enIdx + enChr.length)
            curIdx = stIdx
        } else {
            curIdx = enIdx
        }
    }
    return txt
}

/**
 * Find in container element, add height to equal size of element
 * @param container css path where my element is contain e.g. `.c-container`
 * @param el css path of elements to equalized
 */
export const equalHeight = (containerSelector: string, el: string, elementScope: HTMLElement) => {
    const container = elementScope.querySelector<HTMLElement>(containerSelector)
    if (!container) {
        return
    }

    const items = container.querySelectorAll<HTMLElement>(el)
    let max = -1

    for (let i = 0; i < [...items].length; i++) {
        const item = [...items][i]
        let h = item.offsetHeight
        max = h > max ? h : max
    }

    if (max <= 0) {
        return
    }

    for (let i = 0; i < [...items].length; i++) {
        const item = [...items][i]
        item.style.height = `${max}px`
    }
}

export const stringifyCurlyQuote = (data: {}) => JSON.stringify(data).replace("'", '’')

export const stringifyForAttribute = (data = {}) => {
    return escapeHtml(JSON.stringify(data))
}

/**
 * This function is included instead of sanitizeString because for
 * inserting HTML into innerHTML we need to make sure all HTML
 * entities are escaped.
 */
export const escapeHtml = (text: string) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    } as { [s: string]: string }

    return text.replace(/[&<>"']/g, (m) => map[m])
}

/**
 * Because setting attributes escapes more than the characters above and then preact also
 * escapes text we need a more complete way of unescaping all html entities (not just the ones
 * above)
 */
const domParser = new DOMParser()
export const unescapeHtml = (text: string) => {
    return domParser.parseFromString(text, "text/html").body.textContent
}

export const sanitizeString = (data: {}) => {
    if (!data) {
        return ''
    }

    return data.toString().replace(/"/g, '&quot;').replace(/'/g, '&apos;')
}

export const stripTags = (s: string) => {
    return (s || '').replace(/(<([^>]+)>)/gi, '')
}

export const setAttributes = (element: Element, attrs: Record<string, any>) => {
    for (let key in attrs) {
        element.setAttribute(key, attrs[key])
    }
    return element
}

export const getMetaContent = (metaName: string) => {
    const metaTag = document.querySelector(`meta[name=${metaName}]`)
    if (!metaTag) {
        return ''
    }
    return metaTag.getAttribute('content')
}

export const isObjectEmpty = (obj: {}) => {
    return Object.keys(obj).length === 0
}
