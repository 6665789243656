import { impression, viewPromotion } from '@kissui/helpers/src/gtmEvents'

export default class {
    constructor(node, data) {
        this.observer = new IntersectionObserver(
            IntersectionObserverEntry => this.onChange(IntersectionObserverEntry),
            { threshold: [0], rootMargin: '0% 0%' }
        )

        this.el = node
        this.data = data

        if (!(this.el instanceof Element)) {
            return
        }

        this.observer.observe(this.el)
    }

    /**
     * Check if element are in the viewport
     * @param {Array} observables
     * @param {HTMLElement} observables.target
     * @param {Boolean} observables.isIntersecting
     */
    onChange(observables) {
        observables.forEach(observable => {
            if (!observable.isIntersecting) {
                return
            }

            this.track(observable)
            this.observer.unobserve(observable.target)
        })
    }

    track() {
        // This line is needed to make the code compatible with Safari <=13
        if ('requestIdleCallback' in window) {
            window.requestIdleCallback(() => this.runTrack())
        } else {
            window.setTimeout(() => this.runTrack(), 1)
        }
    }

    runTrack() {
        if (!this.data['id'] || this.data['id'] === null || this.data['id'] === '') {
            return
        }
        impression({ ecommerce: { promoView: { promotions: [this.data] } } })
        viewPromotion({ ...this.data })
    }
}
