import { BREAKPOINT_M, BREAKPOINT_TABLET } from '@kissui/components'

const viewport = () => {
    const lt = (ref: number) => {
        return window.innerWidth < ref
    }

    return {
        get is() {
            const { innerWidth: vw, devicePixelRatio } = window
            return {
                mobile: vw < BREAKPOINT_M,
                mobile_tablet: vw < BREAKPOINT_TABLET,
                tablet: vw >= BREAKPOINT_M && vw < BREAKPOINT_TABLET,
                desktop: vw >= BREAKPOINT_TABLET && devicePixelRatio <= 1,
                retina: vw >= BREAKPOINT_TABLET && devicePixelRatio > 1
            }
        },
        lt
    }
}

const helper = viewport()

export default helper
