import zoneTracking from '@kissui/helpers/src/assets/js/zoneTracking'
import { lazyLoad } from '@kissui/helpers/src/utils'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'

class Container extends HTMLElement {
    constructor() {
        super()
        this.slot = this.innerHTML
    }

    connectedCallback() {
        this.props = [...this.attributes].reduce((all, attr) => {
            return { ...all, [attr.nodeName]: attr.nodeValue }
        }, {})
        this.render()
        this.initTracking()
        this.initLazyLoad()
    }

    initTracking() {
        const {
            campaign_id = null,
            campaign_name = null,
            campaign_position = null,
            campaign_creative = null
        } = this.props
        zoneTracking(this, {
            id: campaign_id,
            name: campaign_name,
            position: campaign_position,
            creative: campaign_creative
        })
    }

    initLazyLoad() {
        const el = this.querySelector('.cb-bg-img')
        if (!el) {
            return
        }

        const url = this.getBgImgUrl()
        if (!url) {
            return
        }

        const { lazy_load } = this.props
        if (lazy_load === 'false') {
            el.setAttribute('style', `background-image: url(${url})`)
            return
        }

        lazyLoad(
            el,
            'style',
            `background-image: url(${url}); ${el.getAttribute('style') || ''}`,
            url
        )
    }

    getBgImgUrl() {
        const {
            background_mobile = '',
            background_tablet = '',
            background_desktop = '',
            background_retina = ''
        } = this.props

        let url = ''
        if (viewportHelper.is.mobile && background_mobile) {
            url = background_mobile
        } else if (viewportHelper.is.tablet && background_tablet) {
            url = background_tablet
        } else if ((viewportHelper.is.retina || viewportHelper.is.tablet) && background_retina) {
            url = background_retina
        } else if (
            (viewportHelper.is.desktop || viewportHelper.is.retina || viewportHelper.is.tablet) &&
            background_desktop
        ) {
            url = background_desktop
        }

        return url
    }

    render() {
        const {
            styles = '',
            contrast,
            background_color = '',
            classname = '',
            element_class = '',
            slot
        } = this.props

        // Fix for undefined contrast (contrast is needed for background-color to work)
        let contrastDefault = 'light'
        if (contrast === 'undefined') {
            this.setAttribute('contrast', contrastDefault)
        } else {
            contrastDefault = contrast
        }

        this.innerHTML = `
            <section
                class="${classname}"
                contrast="${contrastDefault}"
                style="${styles}"
            >
                ${background_color || this.hasBgImage() ? this.renderBg() : ''}
                <div class="cb-inner ${element_class}">
                    ${slot}
                </div>
            </section>`
    }

    hasBgImage() {
        const { background_mobile: m, background_desktop: d, background_retina: r } = this.props
        return !!m || !!d || !!r
    }

    renderBg() {
        const { background_color = '' } = this.props

        return `<div class="cb-bg-color ${background_color ? background_color : ''}">
        </div>${this.hasBgImage() ? '<div class="cb-bg-img"></div>' : ''}`
    }
}

customElements.get('nb-container') || customElements.define('nb-container', Container)

export default Container
